<template>
  <div class="sign-subscription-agreement">
    <div class="white-box has-background-white">
      <div class="box-title has-text-centered has-text-weight-semibold">Sign documents</div>
      <div class="box-subtitle has-text-centered">Let’s make this official! In order to finalize this process, you need to sign some documents.</div>
      <div class="purple-box">
        <div class="has-text-weight-semibold">{{signBoxText}}</div>
        <div class="is-relative">
          <button class="button is-blue is-fullwidth sign-agreement-button has-text-centered has-text-weight-semibold" @click="signSubAgreement">
            <img src="@assets/icons/file-white.svg" alt="sign agreement">
            Sign my documents
          </button>
          <button class="button is-loading" v-show="showLoading"></button>
          <img
            v-if="signed"
            src="@assets/icons/check-white.svg"
            alt="agreement signed"
            class="check-mark">
        </div>
        <div class="has-text-centered">
          <img
            :src="`https://addy-public.s3.us-west-2.amazonaws.com/sub-agreement-${signed ? 'complete' : 'pending'}.svg`"
            class="sign-agreement-status"
            alt="sign agreement status">
        </div>
        <div class="sign-platform has-text-lavender has-text-centered">
          We use {{useAddySign ? 'our own service, addySign,' : 'HelloSign'}} to facilitate our contract signing.
        </div>
      </div>
    </div>
    <template v-if="!embeddedByIos">
      <BottomButtons
        backButtonText="Back"
        :nextDisabled="!signed"
        :showSteps="false"
        :backButtonVisible="false"
        @goNext="goNext"
        @goBack="goBack"
      ></BottomButtons>
    </template>
    <div class="addySign-container" v-if="showAddySignModal">
      <button class="modal-close is-large" @click="closeAddySignModal"></button>
      <iframe :src="addySignUrl" title="Subscription Agreement" id="addySign-iframe"></iframe>
    </div>
    <div class="transparent-mask" v-if="showTransparentMask"></div>
    <Flash
      title="Notice!"
      content="You exceeded the one hour countdown for signing. We have to release the purchase back into the pool."
      :show="showSigningExpiryFlash"
      :hideFlash="hideSigningExpiryFlash"
    ></Flash>
  </div>
</template>
<script>
import BottomButtons from '@components/button/bottom-buttons.vue'
import Flash from '@components/modal/slide-down-flash.vue'
import { getBuyStatus, getHelloSignForSubAgreement, signedSubAgreementThruHelloSign, getAddySignUrl } from '@api/properties'
import { getFeatureToggleList, getMembershipStatus } from '@api/common'
import axios from '@lib/axios/middleware'
import { getEnvironment } from '@utils/common-methods/getEnvironment'
import { toggleIntercom } from '@utils/common-methods/intercom'
import HelloSign from 'hellosign-embedded'

export default {
  data() {
    return {
      subAgreementSigningStatus: 'pending',
      showLoading: false,
      useAddySign: true,
      showAddySignModal: false,
      addySignUrl: '',
      showTransparentMask: false,
      showSigningExpiryFlash: false,
    }
  },
  computed: {
    nameSpaceUrl() {
      return this.$route.params.nameSpaceUrl
    },
    propertyId() {
      return this.$route.params.propertyId
    },
    buyId() {
      return this.$route.query.buyId
    },
    signed() {
      return this.subAgreementSigningStatus === 'complete'
    },
    signBoxText() {
      return this.signed ? 'You’ve successfully signed the legal documents.' : 'You’ve got some required legal documents to sign.'
    },
    embeddedByIos() {
      return this.$route.query.autoloadSigning === 'true'
    },
    nextPageRoute() {
      return `/${this.nameSpaceUrl}/buy/share/${this.propertyId}/${this.buyId}`
    },
  },
  components: {
    BottomButtons,
    Flash,
  },
  created() {
    this.addMaskForiOS()
    this.cleanMembershipTypeIfPresentInLocalStorage()
    this.getMembershipTypeAndToggle()
  },
  methods: {
    getPurchaseStatus(doAutoSigning = false) {
      getBuyStatus(this.propertyId, this.buyId).then((result) => {
        if (!result.success) return this.handleSigningExpiry()

        this.parseBuyStatus(result.data.buy.status)
        if (this.signed) return this.$router.push(this.nextPageRoute)
        doAutoSigning && this.handleEmbedding()
      })
    },
    handleSigningExpiry() {
      this.showSigningExpiryFlash = true
      setTimeout(() => {
        this.$router.replace('/allProperties')
      }, 3000)
    },
    hideSigningExpiryFlash() {
      this.showSigningExpiryFlash = false
    },
    getMembershipTypeAndToggle() {
      axios.all([
        getMembershipStatus(),
        getFeatureToggleList(),
      ]).then((result) => {
        if (!result[0].success || !result[1].success) return

        const { membershipType } = result[0].data
        const isAccredited = membershipType === 'accredited_membership'
        const { addySignSubscriptionAgreement, accreditedAddySignSubscription } = result[1].data

        this.useAddySign = isAccredited ? accreditedAddySignSubscription : addySignSubscriptionAgreement

        this.getPurchaseStatus(true)
      })
    },
    parseBuyStatus(status) {
      switch (status) {
      case 'new':
      case 'token_escrowed':
        this.subAgreementSigningStatus = 'pending'
        break
      case 'agreements_signed':
        this.subAgreementSigningStatus = 'complete'
        break
      default:
        this.subAgreementSigningStatus = 'complete'
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    goNext() {
      this.$router.push(this.nextPageRoute)
    },
    signSubAgreement() {
      if (this.showLoading || this.signed) return
      this.showLoading = true

      if (this.useAddySign) this.useAddySignForSubscription()
      else this.useHelloSignForSubscription()
    },
    useAddySignForSubscription() {
      getAddySignUrl(
        this.buyId,
        encodeURIComponent(`${window.location.origin}${this.nextPageRoute}`)
      ).then((result) => {
        if (!result.success) return
        this.addySignUrl = result.data.addySignUrl
        this.toggleAddySignModal(true)
      })
    },
    toggleAddySignModal(status) {
      this.showLoading = status
      toggleIntercom(status)
      this.showAddySignModal = status
      if (!status) this.getPurchaseStatus()
    },
    useHelloSignForSubscription() {
      getHelloSignForSubAgreement(
        this.propertyId,
        this.buyId,
      ).then((result) => {
        if (!result.success) return
        toggleIntercom(true)

        const { url, clientId } = result.data.hellosign
        const isProduction = getEnvironment() === 'production'

        const client = new HelloSign({ clientId })
        client.open(url, {
          skipDomainVerification: !isProduction,
          allowCancel: true,
        })
        client.on('sign', () => {
          signedSubAgreementThruHelloSign(
            this.propertyId,
            this.buyId,
          ).then((res) => {
            if (this.embeddedByIos) return window.webkit.messageHandlers.sign.postMessage(true)
            if (!res.success) return
            this.showLoading = false

            client.close()
            this.subAgreementSigningStatus = 'complete'

            toggleIntercom(false)
          })
        })
        client.on('cancel', () => {
          this.showLoading = false
          toggleIntercom(false)
          if (this.embeddedByIos) window.webkit.messageHandlers.sign.postMessage(true)
        })
      })
    },
    cleanMembershipTypeIfPresentInLocalStorage() {
      const membershipType = window.localStorage.getItem('membershipTypeInPropBuy')
      if (membershipType) window.localStorage.removeItem('membershipTypeInPropBuy')
    },
    handleEmbedding() {
      this.embeddedByIos && this.signSubAgreement()
    },
    closeAddySignModal() {
      if (this.embeddedByIos) window.webkit.messageHandlers.sign.postMessage(true)
      else this.toggleAddySignModal(false)
    },
    addMaskForiOS() {
      if (this.embeddedByIos) this.showTransparentMask = true
    },
  },
}
</script>
<style lang="scss" scoped>
.sign-subscription-agreement {
  font-size: 16px;
  line-height: 19px;
  padding: 12px 25px 100px;
  .transparent-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  .white-box {
    padding: 20px 15px 16px;
    border-radius: 12px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
    margin-bottom: 28px;
    .box-title {
      margin-bottom: 8px;
    }
    .box-subtitle {
      margin-bottom: 40px;
    }
    .purple-box {
      padding: 16px 17px;
      height: 284px;
      background: #F0EEF8;
      border-radius: 6px;
      .sign-agreement-button {
        height: 43px;
        border-radius: 12px;
        margin-top: 12px;
        img {
          margin-right: 6px;
        }
      }
      .button.is-loading {
        position: absolute;
        right: 2px;
        top: 2px;
        background-color: transparent;
        border-color: transparent;
      }
      .check-mark {
        position: absolute;
        right: 16px;
        top: 12px;
      }
      .sign-agreement-status {
        width: 78px;
        margin: 10px auto 16px;
      }
      .sign-platform {
        padding: 0 28px;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
  .addySign-container {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .modal-close {
      top: 0.5rem;
      left: 6px;
      &::before,
      &::after {
        background-color: #4A26AA;
      }
    }
    #addySign-iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
