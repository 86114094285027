<template>
  <div class="investment-confirmation">
    <NavBack/>
    <div class="white-box has-background-white">
      <div class="box-title has-text-centered has-text-weight-semibold">Investment confirmation</div>
      <div class="purchase-items-wrap">
        <div class="is-flex is-justify-content-space-between price-per-share">
          <span class="item-title">Price per share ({{currency}})</span>
          <span class="item-value">$1.00</span>
        </div>
        <div class="is-flex is-justify-content-space-between num-of-share">
          <span class="item-title">Shares to purchase</span>
          <span class="item-value">{{amount | formatAmount}}</span>
        </div>
        <!-- <div class="is-flex is-justify-content-space-between membership-charge" v-if="membershipChargeIncludesTax">
          <span class="item-title">{{confirmedMembershipType}} Membership + tax</span>
          <span class="item-value">{{membershipChargeIncludesTax | formatCurrency}}</span>
        </div> -->
        <div class="is-flex is-justify-content-space-between total">
          <span class="has-text-blue has-text-weight-semibold item-title">Total ({{currency}})</span>
          <!-- <span class="has-text-blue item-value">{{amount + membershipChargeIncludesTax | formatCurrency}}</span> -->
          <span class="has-text-blue item-value">{{amount | formatCurrency}}</span>
        </div>
      </div>
      <div class="om-box">
        <div class="view-om-modal is-flex has-text-weight-semibold is-justify-content-space-between is-align-items-center is-clickable" @click="omModalIsActive = true">
          {{isAmerican ? 'Offering circular' : (isCrowdfunding ? 'Offering document' : 'Offering memorandum')}} confirmation
          <img src="@assets/icons/info.svg" alt="confirmation">
        </div>
        <button
          class="button is-blue is-fullwidth om-button has-text-centered has-text-weight-semibold"
          @click="viewOm(isCrowdfunding ? 'crowd_funding_document' : 'offering_memorandum_document')"
        >
          <img src="@assets/icons/file-white.svg" alt="Offering Memorandum">
          Read the {{isAmerican ? 'OC' : (isCrowdfunding ? 'Offering Document' : 'OM')}}
        </button>
        <button class="button is-blue is-fullwidth om-button has-text-centered has-text-weight-semibold mt-3" @click="viewOm('french_offering_memorandum_document')" v-if="hasFrenchOM">
          <img src="@assets/icons/file-white.svg" alt="Offering Memorandum">
          Lire la notice d'offre
        </button>
        <div class="om-check">
          <b-checkbox v-model="checked" type="is-blue">
            I have read and understood the contents of the {{isAmerican ? 'Offering Circular' : (isCrowdfunding ? 'Offering Document' : 'Offering Memorandum')}}
          </b-checkbox>
        </div>
      </div>
    </div>
    <BottomButtons
      nextButtonText="Invest"
      backButtonText="Back"
      :nextDisabled="isNextDisabled"
      :loading="isLoading"
      :showSteps="false"
      @goNext="goNext"
      @goBack="goBack"
    ></BottomButtons>
    <StandardModal
      :showModal="omModalIsActive"
      :closeModal="hideOmModal"
      :title="`What is an ${isAmerican ? 'OC' : (isCrowdfunding ? 'Offering Document' : 'OM')}?`"
      buttonTitle="Gotcha"
      >
      <div class="modal-text">
        <div class="mb-4">
          An {{isAmerican ? 'offering circular' : (isCrowdfunding ? 'offering document' : 'offering memorandum')}}
          is a legal document that outlines the opportunity, risks, and plans for this property 📄.
        </div>
        <div>These documents are put together by addy’s real estate team that summarizes the details of this investment opportunity for you 😌.</div>
      </div>
    </StandardModal>
    <StandardModal
      :clickableBackground="false"
      :hasCloseIcon="false"
      title="Oops! Something went wrong"
      buttonTitle="Back to property"
      :showModal="showErrorModal"
      :buttonTappedAction="toPropertyDetailsPage"
      :closeModal="() => {showErrorModal = false}"
    >
      <div class="modal-text">
        <div class="mb-4">{{errorModalText}}</div>
      </div>
    </StandardModal>
  </div>
</template>
<script>
import { formatter } from '@utils/common-methods/numberFormatter'
import { isAmericanUser } from '@utils/common-methods/common'
import BottomButtons from '@components/button/bottom-buttons.vue'
import StandardModal from '@components/modal/standard-modal.vue'
import NavBack from '@components/desktop/header-nav-back.vue'
import { getPreviewInfo, buyShare } from '@api/properties'
import { fetchDocUrl } from '@api/common'

export default {
  data() {
    return {
      maxPurchaseAllowed: 0,
      walletBalance: '',
      corporationId: '',
      membershipPaid: false,
      membershipChargeIncludesTax: 0,
      checked: false,
      omModalIsActive: false,
      isLoading: false,
      selectedMembershipType: undefined,
      hasFrenchOM: false,
      purchaseProcessType: '',
      country: 'Canada',
      showErrorModal: false,
      errorModalText: '',
    }
  },
  computed: {
    nameSpaceUrl() {
      return this.$route.params.nameSpaceUrl
    },
    propertyId() {
      return this.$route.params.propertyId
    },
    amount() {
      return Number(this.$route.query.amount)
    },
    exceedMax() {
      return this.amount > this.maxPurchaseAllowed
    },
    exceedBalance() {
      return this.amount > Number(this.walletBalance)
    },
    isNextDisabled() {
      return !this.checked || this.exceedMax || this.exceedBalance
    },
    confirmedMembershipType() {
      return this.selectedMembershipType === 'accredited_membership' ? 'Accredited' : 'addyOne'
    },
    isAmerican() {
      return isAmericanUser()
    },
    isCrowdfunding() {
      return this.purchaseProcessType === 'crowdfunding'
    },
    currency() {
      return this.country === 'Canada' ? 'CAD' : 'USD'
    },
  },
  filters: {
    formatAmount(value) {
      return formatter(value, 0)
    },
    formatCurrency(value) {
      const number = Number(value)
      return number.toLocaleString('en-CA',
        { style: 'currency', currency: 'CAD' }
      )
    },
  },
  components: {
    NavBack,
    BottomButtons,
    StandardModal,
  },
  created() {
    this.getData()
  },
  methods: {
    getData(selectedMembershipType = null) {
      getPreviewInfo(this.propertyId, selectedMembershipType).then((result) => {
        if (!result.success) return
        const { walletBalance, corporationId, purchaseLimit, totalPurchasedAmount, hasFrenchOM, purchaseProcessType, country } = result.data.property
        this.walletBalance = walletBalance
        this.corporationId = corporationId
        this.maxPurchaseAllowed = purchaseLimit - totalPurchasedAmount
        this.hasFrenchOM = hasFrenchOM
        this.purchaseProcessType = purchaseProcessType
        this.country = country

        this.membershipPaid = result.data.investor.membershipPaid
        if (result.data.membershipCharge) {
          const { chargeAmount, taxAmount } = result.data.membershipCharge
          this.membershipChargeIncludesTax = chargeAmount + taxAmount
        }
        // if (!this.membershipPaid && !result.data.membershipCharge) {
        //   this.selectedMembershipType = window.localStorage.getItem('membershipTypeInPropBuy')
        //   this.getData(this.selectedMembershipType)
        // }
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    goNext() {
      this.isLoading = true
      buyShare(
        this.propertyId,
        this.amount,
        'mobile_web',
        // this.membershipPaid ? null : this.selectedMembershipType,
      ).then((result) => {
        this.isLoading = false
        if (!result.success) return this.handleBuyError(result.error)
        this.clearAvailableCountryForInvestment()
        this.$router.replace(`/${this.nameSpaceUrl}/buy/sign/${this.propertyId}?buyId=${result.data.buy.id}`)
      })
    },
    clearAvailableCountryForInvestment() {
      const country = localStorage.getItem('availableCountryForInvestment')
      country && localStorage.removeItem('availableCountryForInvestment')
    },
    handleBuyError(errorMessage) {
      this.errorModalText = errorMessage
      this.showErrorModal = true
    },
    hideOmModal() {
      this.omModalIsActive = false
    },
    async viewOm(type) {
      const w = window.open('')
      const response = await fetchDocUrl({
        model_name: 'Corporation',
        hashid: this.corporationId,
        document_type: type,
      })
      if (!response.success || !response.data.url) return w.close()
      w.location.href = response.data.url
    },
    toPropertyDetailsPage() {
      this.$router.replace(`/${this.nameSpaceUrl}/buy/${this.propertyId}`)
    },
  },
}
</script>
<style lang="scss" scoped>
.investment-confirmation {
  font-size: 16px;
  padding: 12px 25px 60px;
  .white-box {
    padding: 20px 15px 16px;
    border-radius: 12px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
    margin-bottom: 28px;
    .box-title {
      margin-bottom: 24px;
    }
    .purchase-items-wrap {
      padding: 0 7px;
      .price-per-share,
      .num-of-share,
      .membership-charge {
        padding-bottom: 12px;
      }
      .total {
        border-top: 1px solid #9185AE;
        padding-top: 12px;
        .item-title,
        .item-value {
          font-size: 24px;
        }
      }
    }
    .om-box {
      margin-top: 24px;
      background-color: #F0EEF8;
      border-radius: 6px;
      padding: 17px 16px 17px;
      .view-om-modal {
        margin-bottom: 16px;
      }
      .om-button {
        height: 43px;
        border-radius: 12px;
        img {
          margin-right: 6px;
        }
      }
      .om-check {
        padding-left: 15px;
        padding-top: 15px;
        .b-checkbox.checkbox {
          align-items: start;
        }
      }
    }
  }
}
</style>
<style scoped>
.om-check .b-checkbox >>> .check {
  margin-top: 4px;
  border-color: #4A26AA !important;
}
</style>
