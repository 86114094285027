<template>
  <div :class="`share-purchase ${$isMobile ? 'is-mobile' : 'pt-6'}`">
    <div v-if="$isMobile">
      <div class="fixed-background"></div>
      <MenuAndLogo></MenuAndLogo>
      <div :class="`close-icon has-text-weight-bold is-flex is-justify-content-center is-align-items-center`" @click="toPortfolioPage">
        <img src="@assets/icons/close-thick.svg" alt="gift">
      </div>
    </div>
    <div class="share-content">
      <div class="white-box has-background-white has-text-centered">
        <div class="box-title has-text-centered has-text-weight-semibold">Congrats! You're almost officially an owner of {{certificateTitle}}! What's next?</div>
        <div class="box-subtitle">
          We are reviewing your investment and will let you know shortly when the transaction is completed. We may also follow-up with a few questions, so sit tight, you are almost done!
        </div>
        <div class="box-subtitle">
          Once approved, you'll earn distribution(s) from this property and receive regular owners' updates. Plus, the property has been added to your addyverse.
        </div>
        <lottie-player
          class="confetti"
          autoplay
          mode="normal"
          src="https://assets10.lottiefiles.com/packages/lf20_wrcc9v6e.json"
          style="width: 320px; height: 220px;"
          v-if="showConfetti"
        >
        </lottie-player>
      </div>
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <ShareButton
          buttonText="Spread the Word!"
        ></ShareButton>
      </div>
      <div class="drag-wrap" id="drag-container">
        <div
          id="certificate-wrap"
          ref="certificate"
          draggable="true"
          @touchstart="dragStartCertificate"
          @touchmove="dragCertificate"
        >
          <div class="rotate">
            <InvestmentShareCertificate
              :certificateTitle="certificateTitle"
              :memberFullName="investorName"
              :securityType="securityType"
              :securityCount="securityCount"
              :date="date"
              :dateLabel="dateLabel"
            ></InvestmentShareCertificate>
          </div>
        </div>
        <img
          :src="miniPropSticker"
          alt="mini prop sticker"
          id="prop-sticker"
          ref="sticker"
          @touchstart="dragStartSticker"
          @touchmove="dragSticker">
        <img
          src="https://addy-public.s3.us-west-2.amazonaws.com/copped-that-prop.png"
          alt="copped that prop"
          id="cop-prop"
          ref="tag"
          @touchstart="dragStartTag"
          @touchmove="dragTag">
      </div>
      <div v-if="!$isMobile" class="bottom-buttons is-flex is-flex-direction-column is-align-items-center" id="done-button">
        <div class="has-text-lavender has-text-weight-bold done-button px-4 py-1 mt-3 is-clickable" @click="toPortfolioPage">Done</div>
      </div>
    </div>
  </div>
</template>
<script>
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import InvestmentShareCertificate from '@views/portfolio/share/InvestmentShareCertificate'
import ShareButton from '@components/button/share-button.vue'
import { toggleIntercom } from '@utils/common-methods/intercom'
import { setDraggableElement } from '@utils/common-methods/common'
import nps from '@utils/mixins/nps.js'
import { getSharingDetails } from '@api/properties'
import '@lottiefiles/lottie-player'

export default {
  mixins: [nps],
  data() {
    return {
      certificateTitle: '',
      investorName: '',
      securityType: '',
      securityCount: 0,
      date: '',
      dateLabel: '',
      miniPropSticker: '',
      addyHandle: '',
      certificate: {
        startX: 0,
        startY: 0,
      },
      sticker: {
        startX: 0,
        startY: 0,
      },
      tag: {
        startX: 0,
        startY: 0,
      },
      showConfetti: true,
    }
  },
  computed: {
    webShareApiSupported() {
      return !!navigator.share
    },
  },
  components: {
    MenuAndLogo,
    InvestmentShareCertificate,
    ShareButton,
  },
  created() {
    this.getData()
  },
  mounted() {
    setTimeout(() => {
      this.showConfetti = false
    }, 5000)
    toggleIntercom(true)
    this.disablePagePullToRefresh()
    this.setDraggingOnDesktop()
  },
  beforeDestroy() {
    toggleIntercom(false)
    document.body.removeEventListener('touchmove', this.preventDefaultPullToRefresh, { passive: false })
    localStorage.removeItem('dealer_disclaimer')
  },
  methods: {
    disablePagePullToRefresh() {
      document.body.addEventListener('touchmove', this.preventDefaultPullToRefresh, { passive: false })
    },
    preventDefaultPullToRefresh(e) {
      e.preventDefault()
    },
    getData() {
      const { propertyId, buyId } = this.$route.params
      getSharingDetails(propertyId, buyId).then((result) => {
        if (!result.success) return
        const { certificateData, property, investor } = result.data
        this.certificateTitle = certificateData.certificateTitle // certificate title is the property nickname
        this.securityCount = certificateData.securityCount
        this.investorName = certificateData.investorName
        this.dateLabel = certificateData.dateLabel
        this.date = certificateData.date
        this.securityType = certificateData.securityType

        this.miniPropSticker = property.miniPropStickerUrl

        this.addyHandle = investor.addyHandle
      })
    },
    toPortfolioPage() {
      this.$router.push('/portfolio')
    },
    dragStart(e, element) {
      const _this = this.$refs[element]
      this[element].startX = e.targetTouches[0].pageX - _this.offsetLeft
      this[element].startY = e.targetTouches[0].pageY - _this.offsetTop
    },
    drag(e, element) {
      const _this = this.$refs[element]
      let leftX = e.targetTouches[0].pageX - this[element].startX
      let topY = e.targetTouches[0].pageY - this[element].startY
      const thisW = e.targetTouches[0].target.clientWidth
      const parentW = e.targetTouches[0].target.offsetParent.clientWidth
      const thisH = element === 'certificate' ? e.currentTarget.clientHeight + 26 : e.currentTarget.clientHeight
      const parentH = e.currentTarget.offsetParent.clientHeight

      if (leftX <= 0) leftX = 0
      if (leftX >= parentW - thisW) leftX = parentW - thisW

      if (topY <= 0) topY = 0
      if (topY >= parentH - thisH) topY = parentH - thisH

      if (element !== 'certificate') _this.style.left = leftX + 'px'
      _this.style.top = topY + 'px'
    },
    dragStartCertificate(e) {
      this.dragStart(e, 'certificate')
    },
    dragCertificate(e) {
      this.drag(e, 'certificate')
    },
    dragStartSticker(e) {
      this.dragStart(e, 'sticker')
    },
    dragSticker(e) {
      this.drag(e, 'sticker')
    },
    dragStartTag(e) {
      this.dragStart(e, 'tag')
    },
    dragTag(e) {
      this.drag(e, 'tag')
    },
    async setDraggingOnDesktop() {
      if (this.$isMobile) return
      await this.$nextTick()

      setDraggableElement('certificate-wrap', 15)
      setDraggableElement('cop-prop')
      setDraggableElement('prop-sticker')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.share-purchase {
  height: 100%;
  min-height: 100vh;
  position: relative;
  .confetti {
    position: fixed;
    top: 40vh;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
  }
  .fixed-background {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 201px;
    background-color: #F0EEF8;
    border-radius: 0px 0px 24px 24px;
  }
  ::v-deep .share-button {
    width: 270px;
    height: 43px;
  }
  .close-icon {
    position: absolute;
    right: 25px;
    top: 15px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #F0EEF8;;
    > img {
      width: 16px;
    }
  }
  .share-content {
    padding: 12px 25px 100px;
    .white-box {
      font-size: 14px;
      line-height: 19px;
      padding: 20px 18px 16px;
      border-radius: 12px;
      box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
      margin-bottom: 28px;
      .box-subtitle {
        margin-top: 8px;
      }
    }
    .drag-wrap {
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100 - 375px);
      left: 0;
      #certificate-wrap {
        width: 334px;
        position: absolute;
        top: 34px;
        margin-left: 50%;
        transform: translateX(-50%);
        padding-top: 26px;
        cursor: move;
        .rotate {
          transform: rotate(8.7deg);
          .investment-share-certificate {
            width: 100%;
            margin: 0;
          }
        }
      }
      #prop-sticker {
        width: 236px;
        transform: rotate(-8.93deg);
        position: absolute;
        top: 110px;
        left: 24px;
        cursor: move;
      }
      #cop-prop {
        width: 140px;
        position: absolute;
        top: 210px;
        right: 24px;
        cursor: move;
      }
    }
    .bottom-buttons {
      width: 100%;
      position: fixed;
      bottom: 10px;
      left: 0;
      .done-button {
        font-family: 'Proxima Nova', sans-serif;
        font-size: 16px;
        z-index: 200;
        border: 2px solid #9185AE;
        border-radius: 8px;
        padding: 12px 24px !important;
      }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .share-purchase {
    margin: -20px 0px;
    min-height: 750px;
    .confetti {
      position: absolute;
    }
    .share-content {
      .drag-wrap {
        position: absolute;
        height: calc(100% - 408px);
        #certificate-wrap {
          margin-left: unset;
          left: 73px;
          transform: none;
        }
      }
      .bottom-buttons {
        position: absolute;
        bottom: 30px;
      }
    }
  }
}
</style>
<style scoped>
.investment-share-certificate >>> .addy-logo {
  margin-bottom: 0;
}
</style>
