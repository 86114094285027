import { mobileCheck } from '@utils/common-methods/common'
import { getEnvironment } from '@utils/common-methods/getEnvironment'

export const initNps = ({ firstName, lastName, email, month }) => {
  const payload = {
    name: `${firstName} ${lastName}`,
    forceDisplay: true,
    properties: {
      questionProductName: 'addy',
      memberEmail: email,
    },
    onShow: () => {
      // not display more than once in a day
      localStorage.setItem('lastNPS', Date.now() + 24 * 60 * 60 * 1000)
    },
    onRespond: () => {
      // won’t see it again for X months
      localStorage.setItem('lastNPS', Date.now() + month * 30 * 24 * 60 * 60 * 1000)
    },
  }
  const environment = getEnvironment()
  if (mobileCheck()) {
    payload.properties.device = 'Mobile'
    payload.properties.mobileEnvironment = environment
  } else {
    payload.properties.device = 'Desktop'
    payload.properties.environment = environment
  }

  /* eslint-disable-next-line */
  window.delighted.survey(payload)
}
