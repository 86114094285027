<template>
  <div class="choose-amount">
    <NavBack/>
    <div class="white-box has-background-white pb-5">
      <div class="nickname has-text-centered has-text-weight-semibold">Invest in {{nickname}}</div>
      <div class="wallet has-text-centered has-text-weight-semibold">Wallet balance</div>
      <div class="balance has-text-centered">
        <span class="has-text-blue has-text-weight-semibold mr-1 symbol">$</span>
        <span class="has-text-blue num is-relative">{{walletBalance | formatBalance}}
          <span class="has-text-blue has-text-weight-bold currency" v-if="walletBalance !== ''">{{currency}}</span>
        </span>
      </div>
      <div class="add-fund has-text-centered">Want to add funds?
        <span class="has-text-primary has-text-weight-semibold is-clickable" @click="addFund">Click here.</span>
      </div>
      <div class="label-of-input has-text-weight-semibold pl-3">Amount to invest</div>
      <div class="input-wrap is-relative">
        <div class="symbol has-text-blue has-text-weight-semibold">$</div>
        <input
          class="input"
          type="text"
          placeholder="0"
          v-model.trim="amount"
          @input="validateInput"
          @blur="formatInput">
        <div class="has-text-blue has-text-weight-bold currency">{{currency}}</div>
      </div>
      <div class="preset-amounts is-flex is-justify-content-space-between">
        <div
          v-for="(button, index) in presetAmounts"
          :key="index"
          @click="selectAmount(button.key)"
          :class="`has-text-centered has-text-weight-semibold has-text-blue is-clickable ${button.key === selectedButton ? 'selected' : ''}`"
        >{{button.value}}</div>
      </div>
      <div class="errors mt-3" v-if="choseAmount">
        <div class="help px-3" v-show="exceedMax || exceedTenPercents">Sorry, the amount of shares you are trying to buy exceeds the maximum amount.</div>
        <div class="help px-3" v-show="exceedTenPercents">
          Subscription can't exceed 10% of the lower of your annual income and net worth.
          <div class="help">Annual income or net worth is not up-to-date? Please click
            <span class="has-text-primary is-clickable" @click="toAmericanIncomeReporting">
            here</span></div>
        </div>
        <div class="help px-3" v-show="exceedBalance">Sorry, the amount of shares you are trying to buy exceeds your current addy Wallet balance.</div>
        <div class="help px-3" v-show="limitReached">Wow! 😍, We totally dig you want to buy more shares! To enable our mission,
          we limit purchases to {{currentMembershipDetails.purchaseLimit}} shares per {{currentMembershipType === 'addyZero' ? 'free' : currentMembershipType}}
          member.</div>
      </div>
      <!-- <div class="membership-check" v-if="!membershipPaid">
        <b-checkbox v-model="checked" type="is-blue">
          I understand that the {{confirmedMembershipType}} Membership + tax will be deducted from my chosen investment amount.
        </b-checkbox>
      </div>
      <div
        v-if="!membershipPaid && (selectedMembershipType === 'annual_membership' || selectedMembershipType === 'believer_membership')"
        class="switch-membership-type has-text-lavender"
      >I want to become a
        <span
          class="has-text-primary has-text-weight-semibold is-clickable"
          @click="changeMembershipType"
        >
          {{selectedCharter ? 'believer' : 'charter'}} member</span> instead.
      </div> -->
    </div>
    <BottomButtons
      backButtonText="Back"
      :nextDisabled="isAmountInvalid"
      :showSteps="false"
      @goNext="goNext"
      @goBack="goBack"
    ></BottomButtons>
  </div>
</template>
<script>
import { formatter, truncate } from '@utils/common-methods/numberFormatter'
import { isAmericanUser } from '@utils/common-methods/common'
import { getPreviewInfo } from '@api/properties'
import { getWealthInfo } from '@api/profile'
import NavBack from '@components/desktop/header-nav-back.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import walletRoutes from '@utils/mixins/wallet-routes'

export default {
  mixins: [walletRoutes],
  data() {
    return {
      propertyId: undefined,
      nameSpaceUrl: undefined,
      nickname: '',
      walletBalance: '',
      purchaseLimit: 0,
      country: 'Canada',
      amount: '',
      presetAmounts: [
        {
          value: '$100',
          key: '100.00',
        },
        {
          value: '$500',
          key: '500.00',
        },
        {
          value: '$1500',
          key: '1,500.00',
        },
        {
          value: 'MAX',
          key: 'max',
        },
      ],
      selectedButton: undefined,
      selectedMembershipType: undefined,
      membershipPaid: true,
      membershipChargeIncludesTax: 0,
      checked: false,
      choseAmount: false,
      annualIncome: 0,
      netWorth: 0,
      paidMembershipType: undefined,
    }
  },
  computed: {
    maxAmount() {
      const balance = Number(this.walletBalance) - this.membershipChargeIncludesTax
      const max = balance > this.purchaseLimit ? this.purchaseLimit : parseInt(balance)
      return max < 0 ? 0 : max
    },
    amountInNumber() {
      return Number(this.amount.replace(/\.00$/, '').replace(/\D/g, ''))
    },
    exceedMax() {
      if (this.amountInNumber === 0) return false // special case when purchaseLimit is negative
      return this.amountInNumber > this.purchaseLimit
    },
    exceedBalance() {
      return this.amountInNumber > (Number(this.walletBalance) - this.membershipChargeIncludesTax)
    },
    limitReached() {
      return this.purchaseLimit <= 0
    },
    isAmountInvalid() {
      // return !this.amount || this.exceedMax || this.exceedTenPercents || this.exceedBalance || (!this.membershipPaid && !this.checked)
      return !this.amount || this.exceedMax || this.exceedTenPercents || this.exceedBalance
    },
    selectedCharter() {
      return this.selectedMembershipType === 'annual_membership'
    },
    confirmedMembershipType() {
      return this.selectedMembershipType === 'accredited_membership' ? 'Accredited' : 'addyOne'
    },
    isAmericanNonAccredited() {
      const types = ['addyone_membership']
      return isAmericanUser() && types.includes(this.paidMembershipType || this.selectedMembershipType)
    },
    tenPercentsOfTheLower() {
      return 0.1 * Math.min(this.annualIncome, this.netWorth)
    },
    exceedTenPercents() {
      return this.isAmericanNonAccredited && (this.amountInNumber > this.tenPercentsOfTheLower)
    },
    currentMembershipType() {
      const map = {
        addyone_membership: 'addyOne',
        annual_membership: 'charter',
        believer_membership: 'believer',
        accredited_membership: 'accredited'
      }
      const paidMembership = map[this.paidMembershipType] || map[this.selectedMembershipType] // undefined if not exist
      return paidMembership || 'addyZero'
    },
    currentMembershipDetails() {
      const membershipDetails = JSON.parse(localStorage.getItem('membershipDetails'))
      return membershipDetails[this.currentMembershipType]
    },
    isCanadianProp() {
      return this.country === 'Canada'
    },
    currency() {
      return this.isCanadianProp ? 'CAD' : 'USD'
    },
  },
  filters: {
    formatBalance(value) {
      return formatter(truncate(Number(value), 2))
    },
  },
  mounted() {
    this.propertyId = this.$route.params.propertyId
    this.nameSpaceUrl = this.$route.params.nameSpaceUrl
    this.getData()
    this.getWealthDataOfAmerican()
  },
  components: {
    NavBack,
    BottomButtons,
  },
  methods: {
    getData(selectedMembershipType = null) {
      getPreviewInfo(this.propertyId, selectedMembershipType).then((result) => {
        if (!result.success) return
        const { nickname, walletBalance, purchaseLimit, totalPurchasedAmount, country } = result.data.property
        this.nickname = nickname
        this.walletBalance = walletBalance
        this.purchaseLimit = purchaseLimit - totalPurchasedAmount
        this.country = country

        this.membershipPaid = result.data.investor.membershipPaid
        if (this.membershipPaid && !result.data.investor.membershipExpired) this.paidMembershipType = result.data.investor.membershipType

        if (result.data.membershipCharge) {
          const { chargeAmount, taxAmount } = result.data.membershipCharge
          this.membershipChargeIncludesTax = chargeAmount + taxAmount
        }
        // if (!this.membershipPaid && !result.data.membershipCharge) {
        //   this.selectedMembershipType = window.localStorage.getItem('membershipTypeInPropBuy')
        //   this.getData(this.selectedMembershipType)
        // }
      })
    },
    getWealthDataOfAmerican() {
      if (!isAmericanUser()) return
      getWealthInfo().then((result) => {
        if (!result.data) return
        const { annualIncome, netWorth } = result.data
        this.annualIncome = annualIncome
        this.netWorth = netWorth
      })
    },
    addFund() {
      if (this.isCanadianProp) this.toAddFundsPage()
      else this.$router.push('/wallet/wise')
    },
    validateInput() {
      this.selectedButton = undefined
      this.choseAmount = true

      if (!this.amount) return
      this.amount = this.amount.replace(/^0/, '').replace(/\.\d+$/, '').replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    formatInput() {
      this.amount = formatter(this.amountInNumber)
    },
    selectAmount(key) {
      this.choseAmount = true

      this.selectedButton = key
      if (key === 'max') this.amount = formatter(this.isAmericanNonAccredited ? Math.min(this.tenPercentsOfTheLower, this.maxAmount) : this.maxAmount)
      else this.amount = key
    },
    goBack() {
      this.$router.go(-1)
    },
    goNext() {
      this.$router.push(`/${this.nameSpaceUrl}/buy/confirm/${this.propertyId}?amount=${this.amountInNumber}`)
    },
    changeMembershipType() {
      this.resetInput()
      const newType = this.selectedCharter ? 'believer_membership' : 'annual_membership'
      window.localStorage.setItem('membershipTypeInPropBuy', newType)
      this.selectedMembershipType = newType
      this.getData(newType)
    },
    resetInput() {
      this.amount = ''
      this.selectedButton = undefined
      this.choseAmount = false
    },
    toAmericanIncomeReporting() {
      this.$router.push(`/buy/${this.propertyId}/incomeReporting`)
    },
  },
}
</script>
<style lang="scss" scoped>
.choose-amount {
  padding: 12px 25px 60px;
  .white-box {
    padding: 20px 13px 0;
    border-radius: 12px;
    min-height: 375px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
    margin-bottom: 28px;
    .nickname {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
    }
    .wallet {
      font-size: 16px;
      line-height: 19px;
    }
    .balance {
      .symbol {
        font-size: 20px;
        vertical-align: top;
        line-height: 36px;
      }
      .num {
        font-size: 40px;
        line-height: 48px;
        .currency {
          font-size: 16px;
          line-height: 16px;
          position: absolute;
          right: -35px;
          top: 0;
          padding-top: 6px;
        }
      }
    }
    .add-fund {
      color: #9185AE;
      font-size: 14px;
      line-height: 17px;
    }
    .label-of-input {
      font-size: 16px;
      margin-top: 38px;
      padding-bottom: 12px;
    }
    .input-wrap {
      input {
        height: 51px;
        padding-left: 35px;
      }
      .symbol,
      .currency {
        font-size: 16px;
        line-height: 19px;
        position: absolute;
        left: 16px;
        top: 16px;
        z-index: 2;
        &.currency {
          left: unset;
          right: 16px;
        }
      }
    }
    .preset-amounts {
      padding-top: 8px;
      > div {
        font-size: 16px;
        width: calc(25% - 6px);
        height: 49px;
        line-height: 46px;
        border-radius: 12px;
        background-color: #F0EEF8;
        border: 1px solid #F0EEF8;
        &.selected {
          border-color: #4A26AA;
        }
      }
    }
    .errors {
      .help {
        color: #C91717;
      }
    }
    .membership-check {
      padding-left: 15px;
      padding-top: 30px;
      font-size: 16px;
      line-height: 19px;
      .b-checkbox.checkbox {
        align-items: start;
      }
    }
    .switch-membership-type {
      font-size: 16px;
      line-height: 19px;
      padding-left: 15px;
      margin-top: 12px;
    }
  }
}
</style>
<style scoped>
.membership-check .b-checkbox >>> .check {
  margin-top: 4px;
  border-color: #4A26AA !important;
}
</style>
