<template>
  <b-tooltip type="is-dark" :active="!hasWebShare" :always="showCopySuccess">
    <b-button type="is-blue" class="has-text-weight-bold share-button px-6" @click="shareButtonOnClick">{{buttonText}}</b-button>
    <template v-slot:content v-if="showCopySuccess">
      <b-icon icon="check" class="is-success"></b-icon> Copied
    </template>
    <template v-slot:content v-else>
      <b-icon icon="copy"></b-icon> Click to copy {{shareUrl ? '' : 'referral '}}link
    </template>
  </b-tooltip>
</template>

<script>
import { embeddedInIos } from '@utils/common-methods/common'
import webShareReferralLink from '@utils/mixins/web-share-referral-link'
import webShareTagsString from '@utils/data/web_share_tags.js'
export default {
  mixins: [webShareReferralLink],
  props: {
    buttonText: {
      type: String,
      default: 'Share',
    },
    shareTitle: {
      type: String,
      default: '',
    },
    shareText: {
      type: String,
      default: '',
    },
    shareUrl: {
      type: String,
      default: '',
    },
    embeddedInIosAction: {
      type: Function,
    }
  },
  data() {
    return {
      timer: null,
      showCopySuccess: false,
    }
  },
  computed: {
    hasWebShare() {
      return !!navigator.share
    },
  },
  methods: {
    shareButtonOnClick() {
      if (this.embeddedInIosAction && embeddedInIos()) {
        this.embeddedInIosAction()
      } else if (this.hasWebShare) {
        this.shareText ?
          navigator.share({
            title: this.shareTitle,
            text: this.shareText + webShareTagsString,
            url: this.shareUrl || this.referralLink
          }) :
          this.doWebShare()
      } else {
        this.copyReferralLink()
      }
    },
    copyReferralLink() {
      this.timer && clearTimeout(this.timer)
      this.showCopySuccess = true
      this.$copyText(this.referralLink).then(() => {
        this.timer = setTimeout(() => {
          this.showCopySuccess = false
        }, 3000)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.share-button {
  border-radius: 12px;
  box-shadow: 0px 8px 8px -6px #4e4e4e !important;
}
::v-deep .icon > i {
  color: white;
}
::v-deep .icon.is-success > i {
  color: #3CDBC0 !important;
}
</style>
