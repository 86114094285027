import { initNps } from '@utils/common-methods/nps'

const nps = {
  data() {
    return {
      npsMonth: 2,
    }
  },
  computed: {
    npsInvestor() {
      const investor = localStorage.getItem('investor')
      return investor ? JSON.parse(investor) : {}
    },
  },
  mounted() {
    this.setupNps()
  },
  methods: {
    setupNps() {
      const nextNpsTime = localStorage.getItem('lastNPS') // keep the name as lastNPS in localStorage for backward compatibility
      const payload = {
        firstName: this.npsInvestor.firstName,
        lastName: this.npsInvestor.lastName,
        email: this.npsInvestor.email,
        month: this.npsMonth
      }
      if (!nextNpsTime || parseInt(nextNpsTime) < Date.now()) initNps(payload)
    },
  },
}
export default nps
