<template>
  <div class="investment-share-certificate p-4" id="investmentShareCertificate">
    <p class="certificate-title has-text-weight-semibold">{{ certificateTitle }}</p>
    <p class="certificate-title has-text-weight-semibold mb-3">Share Certificate</p>
    <p class="is-base-text-size">This certifies that</p>
    <p class="is-size-4 has-text-weight-bold has-text-blue mt-2 mb-2">{{ memberFullName }}</p>
    <p class="mb-3 is-base-text-size">is a registered holder of {{ securityCount }} {{ securityCount | pluralize(securityType) }}</p>
    <div class="date">
      <p class="is-base-text-size is-date has-text-weight-semibold">{{ date }}</p>
      <span class="is-inline-block underline my-1"></span>
      <p class="is-uppercase has-label-over-line">{{ dateLabel }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    certificateTitle: {
      type: String,
      required: true,
    },
    memberFullName: {
      type: String,
      required: true,
    },
    securityType: {
      type: String,
      required: true,
    },
    securityCount: {
      type: Number,
      required: true,
    },
    dateLabel: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  filters: {
    pluralize: function(amount, singular, plural = `${singular}s`) {
      return parseInt(amount) === 1 ? singular : plural
    },
  },
}
</script>

<style lang="scss" scoped>
.investment-share-certificate {
  background-color: #F0EEF8;
  border-radius: 6px;
  background-image: url('../../../assets/images/portfolio/certificate.png');
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  width: 91vw;
  height: 56vw;
  max-width: 356px;
  max-height: 218px;
  text-align: center;
  margin: 0 auto 1.5rem;
  .addy-logo {
    width: 23px;
    margin-bottom: 6px;
  }
  .is-base-text-size {
    font-size: 0.6rem;
  }
  .certificate-title {
    font-size: 14px;
    line-height: 17px;
  }
  .date {
    margin-top: 40px;
    .has-label-over-line {
      font-size: 0.4rem;
      color: #9185AE;
    }
    .underline {
      width: 110px;
      height: 1px;
      background-color: #9185AE;
    }
    .is-date {
      color: #3DDBC0;
    }
  }
}
</style>
