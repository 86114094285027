<template>
  <div class="confirm-residency pb-6">
    <NavBack text="Cancel" :action="goBack"></NavBack>
    <div class="white-box has-background-white has-text-centered">
      <div class="has-text-weight-semibold box-title mb-2">Confirm tax residence</div>
      <div class="box-subtitle px-5">Please confirm your tax residence by selecting all that apply.</div>
      <b-button
        v-for="(region, index) in regions"
        :key="index"
        :class="`font-calibre is-flex is-justify-content-left ${region.selected ? 'selected' : ''}`"
        @click="toggleSelectedRegion(region.name, index)"
        expanded>{{region.name}}</b-button>
    </div>
    <BottomButtons
      backButtonText="Cancel"
      :nextDisabled="!selected.length"
      :showSteps="false"
      @goNext="goNext"
      @goBack="goBack"></BottomButtons>
    <div :class="`modal confirm-modal px-5 ${confirmModalIsActive ? 'is-active' : ''}`">
      <div class="modal-background"></div>
      <div class="modal-content has-background-white">
        <img src="@assets/icons/close.svg" alt="close" class="icon-close" @click="confirmModalIsActive = false">
        <div class="modal-header has-text-centered">Cancel investment?</div>
        <div class="modal-text">Are you sure you want to leave and cancel your investment? Our properties sell out quickly, so you might not be able to get your hands on it later!</div>
        <div class="is-flex is-justify-content-space-between buttons">
          <b-button type="button-cancel is-blue" class="has-text-weight-bold" @click="confirmModalIsActive = false">No</b-button>
          <b-button type="button-continue" class="has-text-weight-bold" @click="cancelPurchase">Yes</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import { isAmericanUser } from '@utils/common-methods/common'

export default {
  data() {
    return {
      regions: [
        {
          name: 'Canada',
          selected: false,
        },
        {
          name: 'United States',
          selected: false,
        },
        {
          name: 'Other',
          selected: false,
        },
      ],
      selected: [],
      confirmModalIsActive: false,
    }
  },
  components: {
    NavBack,
    BottomButtons,
  },
  computed: {
    propertyId() {
      return this.$route.params.propertyId
    },
  },
  methods: {
    toggleSelectedRegion(region, i) {
      const index = this.selected.indexOf(region)
      if (index >= 0) this.selected.splice(index, 1)
      else this.selected.push(region)
      this.regions[i].selected = index < 0
    },
    goNext() {
      const availableCountry = localStorage.getItem('availableCountryForInvestment') || 'Canada'
      if (this.selected.filter((country) => country !== availableCountry).length) {
        return this.$router.push({
          name: 'regionNotAvailable',
          query: {
            title: 'Invest in a property',
            fromResidency: true
          },
        })
      }

      // TODO: this questionnaire for the Americans might need to moved to the onboarding
      if (isAmericanUser()) this.$router.push(`/buy/questionnaire/${this.propertyId}`)
      else this.$router.push(`/buy/amount/${this.propertyId}`)
    },
    goBack() {
      this.confirmModalIsActive = true
    },
    cancelPurchase() {
      this.$router.push('/allProperties')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.confirm-residency {
  padding: 12px 25px 0;
  .white-box {
    height: 375px;
    border-radius: 12px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
    padding: 20px 12px 0;
    margin-bottom: 28px;
    .box-title {
      font-size: 16px;
    }
    .box-subtitle {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 32px;
    }
    .button {
      height: 48px;
      margin-bottom: 8px;
      border-radius: 12px;
      &:focus {
        border-color: #dbdbdb;
      }
      &.selected {
        background-color: #F0EEF8;
        border-color: #4A26AA;
      }
    }
  }
  .confirm-modal {
    .modal-content {
      position: relative;
      border-radius: 12px;
      padding: 48px 20px 24px;
      max-width: $mobile-wrapper-width;
      .icon-close {
        width: 28px;
        position: absolute;
        left: 16px;
        top: 14px;
      }
      .modal-header {
        font-weight: 600;
        font-size: 28px;
        padding-bottom: 20px;
      }
      .modal-text {
        font-size: 16px;
        padding: 0 10px 30px;
        line-height: 20px;
      }
      .buttons {
        padding: 0 6px;
        .button-continue {
          width: calc(50% - 6px);
          font-size: 16px;
          height: 43px;
          border-radius: 12px;
          border-color: #4A26AA;
        }
        .button-cancel {
          width: calc(50% - 6px);
          font-size: 16px;
          height: 43px;
          border-radius: 12px;
        }
      }
    }
  }
}
</style>
<style scoped>
.confirm-modal .button-continue >>> span {
  color: #4A26AA;
}
</style>
