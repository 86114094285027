const hashtags = [
  'realestate',
  'proptech',
  'crowdfunding',
  'investing',
  'addyinvest',
  'firemovement',
  'financialindependence'
]

const tags = [
  'addyinvest',
]

let webShareTagsString = ''
for (const tag of tags) {
  webShareTagsString += ` @${tag}`
}
for (const hashtag of hashtags) {
  webShareTagsString += ` #${hashtag}`
}

export default webShareTagsString
