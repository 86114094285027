import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * Get Active Properties (for sale and coming soon) - replaced with getAllProperties
 * @return {Promise}
 */
// export const getActiveProperties = () => {
//   return axios.get(getImbyRoute('/api/v1/properties/for_sale'))
// }

/**
 * Get All Properties
 * @return {Promise}
 */
export const getAllProperties = () => {
  return axios.get(getImbyRoute('/api/v1/public/feed/properties'))
}

/**
 * Get property details for the purchase page
 * @param {String} hashId
 * @return {Promise}
 */
export const getPropertyDetails = (hashId) => {
  return axios.get(getImbyRoute(`/api/v1/properties/${hashId}/complete_details`))
}

/**
 * Get info for the amount page
 * @param {String} hashId
 * @param {String} membershipChargeType
 * @return {Promise}
 */
export const getPreviewInfo = (hashId, membershipChargeType = null) => {
  const route = `/api/v1/properties/${hashId}/investment_preview`
  return axios.get(getImbyRoute(membershipChargeType ? `${route}?membership_charge_type=${membershipChargeType}` : route))
}

/**
 * property purchase
 * @param {String} propertyId
 * @param {Number} units
 * @param {String} purchasePlatform
 * @param {String} membershipType
 * @return {Promise}
 */
export const buyShare = (propertyId, units, purchasePlatform, membershipType = null) => {
  const params = {
    units,
    purchase_platform: purchasePlatform,
  }
  if (membershipType) params.membership_charge_type = membershipType
  return axios.post(getImbyRoute(`/api/v1/properties/${propertyId}/buys`), params)
}

/**
 * Get buy status
 * @param {String} propertyId
 * @param {String} buyId
 * @return {Promise}
 */
export const getBuyStatus = (propertyId, buyId) => {
  return axios.get(getImbyRoute(`/api/v1/properties/${propertyId}/buys/${buyId}/status`))
}

/**
 * Get addySign url for signing sub agreement
 * @param {String} buyId
 * @param {String} nextStepUrl
 * @return {Promise}
 */
export const getAddySignUrl = (buyId, nextStepUrl) => {
  return axios.get(getImbyRoute('/api/v1/addy_sign/agreements/fetch_subscription_url'), { params: { buy_id: buyId, next_step_url: nextStepUrl } })
}

/**
 * Get HelloSign attributes for signing sub agreement
 * @param {String} propertyId
 * @param {String} buyId
 * @return {Promise}
 */
export const getHelloSignForSubAgreement = (propertyId, buyId) => {
  return axios.get(getImbyRoute(`/api/v1/properties/${propertyId}/buys/${buyId}/hellosign_agreements_signing`))
}

/**
 * Update signing status of sub agreement signed through HelloSign
 * @param {String} propertyId
 * @param {String} buyId
 * @return {Promise}
 */
export const signedSubAgreementThruHelloSign = (propertyId, buyId) => {
  return axios.put(getImbyRoute(`/api/v1/properties/${propertyId}/buys/${buyId}/hellosign_agreements_signed`))
}

/**
 * Get details of the purchase sharing page
 * @param {String} propertyId
 * @param {String} buyId
 * @return {Promise}
 */
export const getSharingDetails = (propertyId, buyId) => {
  return axios.get(getImbyRoute(`/api/v1/properties/${propertyId}/buys/${buyId}/copped_details`))
}

/**
 * Get available regions
 * @param {String} propertyId
 * @return {Promise}
 */
export const verifyRegion = (propertyId) => {
  return axios.get(getImbyRoute(`/api/v1/properties/${propertyId}/region_supported`))
}
