import { getFeatureToggle } from '@api/common'
import webShareTagsString from '@utils/data/web_share_tags.js'

const webShareReferralLink = {
  data() {
    return {
      referralGatewayExists: false,
    }
  },
  computed: {
    referralLink() {
      const route = this.referralGatewayExists ? 'referralGateway' : 'signup/create'
      const savedHandle = JSON.parse(localStorage.getItem('investor')).addyHandle
      return `${window.location.origin.replace('canada', 'app')}/${route}?addyHandle=${encodeURIComponent(this.addyHandle || savedHandle)}&source=web`
    },
  },
  created() {
    this.getReferralGatewayToggleStatus()
  },
  methods: {
    getReferralGatewayToggleStatus() {
      getFeatureToggle('referral_gateway').then((result) => {
        if (!result.success) return
        this.referralGatewayExists = result.data.enabled
      })
    },
    doWebShare() {
      navigator.share({
        title: 'My referral link',
        text: `I'm investing in real estate with addy 🤝! Become a member with my link and get $25 to kickstart your own investing journey 🏠!` + webShareTagsString,
        url: this.referralLink
      })
    },
  },
}
export default webShareReferralLink
