<template>
  <div :class="`buy-base pt-embedded-in-ios ${$isMobile ? 'is-mobile' : 'pt-6'}`">
    <div v-if="$isMobile" class="fixed-background bg-embedded-in-ios"></div>
    <div>
      <MenuAndLogo v-if="$isMobile" referralIconBackgroundColor="#fff"></MenuAndLogo>
      <div class="page-title has-text-weight-semibold has-text-centered">Invest in a property</div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import MenuAndLogo from '@views/index/MenuAndLogo.vue'

export default {
  components: {
    MenuAndLogo,
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.buy-base {
  min-height: $min-height-mobile-container;
  &.is-mobile { min-height: unset }
  .fixed-background {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 201px;
    background-color: #F0EEF8;
    border-radius: 0px 0px 24px 24px;
  }
  .page-title {
    font-size: 32px;
    line-height: 38px;
  }
}
</style>
