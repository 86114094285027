<template>
  <div class="income-and-networth pb-6">
    <NavBack text="Back" :action="goBack"></NavBack>
    <ValidationObserver ref="questionnaire" v-slot="{ valid }" class="is-block">
      <div class="white-box has-background-white">
        <div class="box-subtitle has-text-centered px-5">Please {{annualIncome && netWorth ? 'confirm' : 'provide'}} your annual income and net worth.</div>
        <ValidationProvider name="income" rules="required" v-slot="{ errors, passed, failed }">
          <div class="field income-field">
            <label class="label has-text-weight-semibold">Annual income (USD)</label>
            <div class="control has-icons-right">
              <input :class="`input is-medium-height ${classForField({ passed, failed })}`" type="number" v-model.trim="annualIncome">
              <span class="icon is-small is-right">
                <img v-show="failed" src="@assets/icons/exclamation.svg" alt="is-danger" style="width: 30px;">
                <img v-show="passed" src="@assets/icons/check.svg" alt="is-success" style="width: 16px;">
              </span>
            </div>
            <p class="help is-danger" v-show="failed">{{errors[0]}}</p>
          </div>
        </ValidationProvider>
        <ValidationProvider name="netWorth" rules="required" v-slot="{ errors, passed, failed }">
          <div class="field net-worth-field mt-3">
            <label class="label has-text-weight-semibold">Net worth (USD)</label>
            <div class="control has-icons-right">
              <input :class="`input is-medium-height ${classForField({ passed, failed })}`" type="number" v-model.trim="netWorth">
              <span class="icon is-small is-right">
                <img v-show="failed" src="@assets/icons/exclamation.svg" alt="is-danger" style="width: 30px;">
                <img v-show="passed" src="@assets/icons/check.svg" alt="is-success" style="width: 16px;">
              </span>
            </div>
            <p class="help is-danger" v-show="failed">{{errors[0]}}</p>
          </div>
        </ValidationProvider>
      </div>
      <BottomButtons
        :nextDisabled="!valid"
        :showSteps="false"
        :loading="nextIsLoading"
        @goNext="goNext"
        @goBack="goBack"
      ></BottomButtons>
    </ValidationObserver>
  </div>
</template>
<script>
import formMixins from '@utils/mixins/form-mixins.js'
import NavBack from '@components/desktop/header-nav-back.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import { getWealthInfo, saveWealthInfo } from '@api/profile'
export default {
  mixins: [formMixins],
  data() {
    return {
      annualIncome: undefined,
      netWorth: undefined,
      nextIsLoading: false,
    }
  },
  components: {
    NavBack,
    BottomButtons,
  },
  computed: {
    propertyId() {
      return this.$route.params.propertyId
    },
  },
  created() {
    this.getWealthData()
  },
  methods: {
    getWealthData() {
      getWealthInfo().then((result) => {
        if (!result.data) return
        const { annualIncome, netWorth } = result.data
        this.annualIncome = annualIncome
        this.netWorth = netWorth
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    goNext() {
      this.nextIsLoading = true
      saveWealthInfo(this.annualIncome, this.netWorth).then((result) => {
        if (!result.success) return
        this.$router.push(`/buy/amount/${this.propertyId}`)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.income-and-networth {
  padding: 12px 25px 0;
  .white-box {
    height: 375px;
    border-radius: 12px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
    padding: 20px 12px 0;
    margin-bottom: 28px;
    .box-subtitle {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 32px;
    }
  }
}
</style>
