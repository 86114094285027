var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"income-and-networth pb-6"},[_c('NavBack',{attrs:{"text":"Back","action":_vm.goBack}}),_c('ValidationObserver',{ref:"questionnaire",staticClass:"is-block",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('div',{staticClass:"white-box has-background-white"},[_c('div',{staticClass:"box-subtitle has-text-centered px-5"},[_vm._v("Please "+_vm._s(_vm.annualIncome && _vm.netWorth ? 'confirm' : 'provide')+" your annual income and net worth.")]),_c('ValidationProvider',{attrs:{"name":"income","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('div',{staticClass:"field income-field"},[_c('label',{staticClass:"label has-text-weight-semibold"},[_vm._v("Annual income (USD)")]),_c('div',{staticClass:"control has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.annualIncome),expression:"annualIncome",modifiers:{"trim":true}}],class:("input is-medium-height " + (_vm.classForField({ passed: passed, failed: failed }))),attrs:{"type":"number"},domProps:{"value":(_vm.annualIncome)},on:{"input":function($event){if($event.target.composing){ return; }_vm.annualIncome=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"icon is-small is-right"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticStyle:{"width":"30px"},attrs:{"src":require("@assets/icons/exclamation.svg"),"alt":"is-danger"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticStyle:{"width":"16px"},attrs:{"src":require("@assets/icons/check.svg"),"alt":"is-success"}})])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"netWorth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('div',{staticClass:"field net-worth-field mt-3"},[_c('label',{staticClass:"label has-text-weight-semibold"},[_vm._v("Net worth (USD)")]),_c('div',{staticClass:"control has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.netWorth),expression:"netWorth",modifiers:{"trim":true}}],class:("input is-medium-height " + (_vm.classForField({ passed: passed, failed: failed }))),attrs:{"type":"number"},domProps:{"value":(_vm.netWorth)},on:{"input":function($event){if($event.target.composing){ return; }_vm.netWorth=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"icon is-small is-right"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticStyle:{"width":"30px"},attrs:{"src":require("@assets/icons/exclamation.svg"),"alt":"is-danger"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticStyle:{"width":"16px"},attrs:{"src":require("@assets/icons/check.svg"),"alt":"is-success"}})])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('BottomButtons',{attrs:{"nextDisabled":!valid,"showSteps":false,"loading":_vm.nextIsLoading},on:{"goNext":_vm.goNext,"goBack":_vm.goBack}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }